<template>
  <b-row class="justify-content-center">
    <b-col md="8">
      <b-card>
        <b-form v-on:submit.prevent>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Warehouse" label-for="warehouse">
                <v-select
                  id="warehouse"
                  v-model="form.selectedWarehouse"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="form.optionsWarehouse"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedWarehouse"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <!-- Selected: <strong>{{ form.selectedWarehouse }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Code" label-for="code">
                <b-form-input
                  id="code"
                  placeholder="Code"
                  v-model="form.code"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Product" label-for="product">
                <v-select
                  id="product"
                  v-model="form.selectedProduct"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="form.optionsProduct"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedProduct"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <!-- Selected: <strong>{{ form.selectedProduct }}</strong> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- submit and reset -->
            <b-col md="12" class="mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                class="mr-1"
                @click="validation()"
              >
                Submit
              </b-button>
              <!-- <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            variant="outline-secondary"
                            >
                            Reset
                            </b-button> -->
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
    <b-col md="4" v-if="form.selectedProduct !== null">
      <b-card>
        <label class="mb-1 font-weight-bolder text-primary"
          >Product Detail</label
        >
        <b-row>
          <b-col>
            <label class="mb-1">Image</label>
            <b-img
              id="image"
              :src="product.url"
              fluid
              alt="Product Image"
              v-b-modal.modal-lg
              class="mb-2"
            />
            <b-modal
              id="modal-lg"
              ok-only
              ok-title="close"
              centered
              size="lg"
              title="Large Modal"
              class="text-center"
            >
              <b-img
                id="image"
                :src="product.url"
                width="1000px"
                fluid
                alt="product image"
              />
            </b-modal>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12"> </b-col>
        </b-row>
        <b-row>
          <b-col md="4"> Name </b-col>
          <b-col md="8" class="text-right font-weight-bolder">
            {{ product.name }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col md="4"> Code </b-col>
          <b-col md="8" class="text-right font-weight-bolder">
            {{ product.code }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col md="4"> Category </b-col>
          <b-col md="8" class="text-right font-weight-bolder">
            {{ product.category_name }}
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col md="4"> Unit </b-col>
          <b-col md="8" class="text-right font-weight-bolder">
            {{ product.unit_name }}
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BImg,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    ToastificationContent,
    vSelect,
    BImg,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        selectedWarehouse: null,
        optionsWarehouse: [],
        selectedProduct: null,
        optionsProduct: [],
        code: "",
      },
      product: {
        name: "",
        url: "",
        code: "",
        category_name: "",
        unit_name: "",
      },
      warehouse_id: null,
      errors: "",
      errMessage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  watch: {
    "form.selectedWarehouse": function (data) {
      this.getProduct(data.value);
    },
    "form.selectedProduct": function (data) {
      this.trigger(data.value);
    },
  },
  created() {
    this.getWarehouse();
  },
  methods: {
    getWarehouse() {
      this.$http.get("warehouses").then((response) => {
        // console.log(response.data.data)
        this.form.optionsWarehouse.push({
          value: null,
          text: "Select 1 Warehouse",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          // console.log($data)
          this.form.optionsWarehouse.push($data);
        }
        // console.log(this.form.optionsWarehouse)
      });
    },
    getProduct($warehouse_id) {
      this.$http
        .get("sales/catalogs?warehouse=" + $warehouse_id)
        .then((response) => {
          // console.log(response.data.data)
          this.form.selectedProduct = null;
          this.form.optionsProduct.splice(0);
          this.form.optionsProduct.push({
            value: null,
            text: "Select 1 Product",
          });

          var datas = response.data.data;
          var i;
          for (i = 0; i < datas.length; i++) {
            var $data = {
              value: datas[i].product_id,
              text: datas[i].product_name,
            };
            // console.log($data)
            this.form.optionsProduct.push($data);
          }
          // console.log(this.form.optionsProduct)
        });
    },
    trigger($id) {
      this.$http.get("product/" + $id).then((response) => {
        // console.log(response.data.data)
        this.product.name = response.data.data.name;
        const { baseUrl } = $themeConfig.app;
        this.product.url = baseUrl;
        this.product.url += "storage/product/";
        this.product.url += response.data.data.image;
        this.product.code = response.data.data.code;
        this.product.category_name = response.data.data.category_name;
        this.product.unit_name = response.data.data.unit_name;
      });
    },
    validation() {
      if (this.form.selectedWarehouse === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Warehouse field is required",
            variant: "warning",
          },
        });
      } else if (this.form.selectedProduct === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Product field is required",
            variant: "warning",
          },
        });
      } else if (this.form.code === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Code field is required",
            variant: "warning",
          },
        });
      } else {
        this.addWarehouseItem();
      }
    },
    addWarehouseItem() {
      this.$http
        .post("warehouse/item/add", {
          warehouse_id: this.form.selectedWarehouse.value,
          product_id: this.form.selectedProduct.value,
          code: this.form.code,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success add Item",
              variant: "success",
            },
          });
          location.href = "/warehouse/item";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>